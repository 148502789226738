@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input {
  display: block;
}
.tachado {
  text-decoration: line-through;
}
/* td:nth-child(2) {
  text-align: right;
  padding-right: 0.5rem;
}
td:nth-child(3) {
  align-items: center;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
} */
